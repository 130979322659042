import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';

export default class extends Controller {
  static targets = ["canvas"]
  static values = { data: Object, toggleLabels: Boolean }

  connect() {
    this.chart = new Chart(this.canvasTarget.getContext('2d'), this.dataValue)

    // disable all to make it easier to toggle the ones I want to see
    if (this.toggleLabelsValue) {
      for(let i=0; i < this.chart.data.datasets.length; i++) {
        this.chart.getDatasetMeta(i).hidden=true;
      }

      this.chart.update();
    }
  }

  disconnect() {
    this.chart.destroy()
    this.chart = undefined
  }
}

import $ from "jquery";

import Highcharts from "highcharts";

import Rails from "@rails/ujs";
try {
  Rails.start();
  // window.Rails = Rails;
} catch {}

import "@hotwired/turbo-rails";

import "bootstrap";

import "../controllers";

var app = function () {
  // Highcharts
  $("[data-highchart]").each(function () {
    let data = $(this).data("highchart");

    // parse json for highcharts
    for (var serie of data.series) {
      for (var entry of serie.data) {
        entry[0] = 1000 * entry[0];
        entry[1] = parseFloat(entry[1]);
      }
    }

    Highcharts.chart(this, {
      title: false,
      chart: {
        zoomType: "x",
      },
      xAxis: {
        type: "datetime",
        plotLines: data.notes,
      },
      series: data.series,
    });
  });

  // Po'mans autocomplete for food logs
  $("#food_log_text").on("change", function () {
    var optionslist = $("datalist")[0].options;
    var value = $(this).val();

    for (var x = 0; x < optionslist.length; x++) {
      if (optionslist[x].value === value) {
        var elem = $(optionslist[x]);
        $("#food_log_calories").val(elem.data("calories"));
        break;
      }
    }
  });

  setTimeout(function() {
    $('.alert').fadeOut('slow');
  }, 3000);
};

// DOMContentLoaded -> turbolinks:load
$(document).on("turbo:load", () => app());
